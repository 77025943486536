<template>
  <div class="airdrop">
    <div>
      <img src="@/assets/img/airdrop_bg.png" class="airdrop_bg" />
    </div>
    <div class="contribute">
      <div class="contribute_li">
        <span>{{$t('lang.swap486')}}</span>
        <img src="@/assets/img/airdrop.png" class="contribute_img" />
      </div>
      <div class="contribute_li">
        <span>{{$t('lang.swap487')}}</span>
        <img src="@/assets/img/masonry.png" class="contribute_img" />
      </div>
    </div>
    <div class="receive">
      <div class="receive_li">
        <span>{{$t('lang.swap488')}}</span>
        <span>{{freeze}}</span>
      </div>
      <div class="receive_li">
        <span>{{$t('lang.swap489')}}</span>
        <span>{{total_airdrop}}</span>
      </div>
      <div class="receive_li">
        <span>{{$t('lang.swap490')}}</span>
        <span>{{usable}}</span>
      </div>
      <van-button type="primary" class="receive_button" @click="getAirdrop">{{$t('lang.swap491')}}</van-button>
    </div>
    <div class="recording">
      <div class="heading">
        <span>{{$t('lang.swap492')}}</span>
      </div>
      <div class="current">
        <span>{{$t('lang.swap493')}}</span>
        <span class="special">{{$t('lang.swap181')}}</span>
        <span class="particular">{{$t('lang.swap106')}}</span>
      </div>
      <div v-if="list.length == 0" class="nothing">
        <img src="@/assets/img/nothingBlank.png" class="nothing_img" />
        <span>{{$t('lang.swap118')}}</span>
      </div>
      <van-list v-else v-model="loading" :finished="finished" finished-text @load="getList">
        <div class="list" v-for="(item,index) of list" :key="index">
          <div class="li">
            <span>{{item.created_time.substring(0,10)}}</span>
            <span class="special">{{Math.abs(item.usable_change)}}</span>
            <span class="particular">VRT</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { airdopApi } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      freeze: 0, //待领取空头
      total_airdrop: 0, //总空投
      usable: 0, //可领取
      behavior_id: 0,
      list: [],
      page: 1,
      pageSize: 10, //页数
      totalPage: 0, //总页数
      loading: false,
      finished: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
        this.getWalletInfo();
        this.getBehavior();
      }
    },
    getWalletInfo() {
      airdopApi.airDropWallet(this.account).then(res => {
        this.freeze = res.data.freeze;
        this.total_airdrop = res.data.total_airdrop;
        this.usable = res.data.usable;
      });
    },
    getBehavior() {
      airdopApi.recordBehaviors(this.account).then(res => {
        for (let i of res.data) {
          if (i.text == '领取') {
            this.behavior_id = i.id;
          }
        }
        this.getList();
      });
    },
    getAirdrop(){
      let web3 = new Web3(window.ethereum);
      web3.eth.personal.sign(web3.utils.fromUtf8('Airdrop Withdraw'), this.account, (err, res) => {
        let data = {
          signature: res,
        };
        airdopApi.getRecord(data, this.account).then(res => {
          if (res.code == 200) {
            this.$toast(this.$t('lang.swap417'));
            this.page = 1;
            this.list = []
            this.loading = false
            this.finished = false
            this.init()
          } else {
            this.$toast(res.msg);
          }
        });
      });
    },
    getList() {
      let data = {
        page: this.page,
        page_size: this.pageSize,
      };
      airdopApi.recordList(data, this.account).then(res => {
        if (res.code == 200) {
          this.list = this.list.concat(res.data.list);
          this.totalPage = Math.ceil(res.data.total_count / this.pageSize);
          this.page++;
          this.loading = false;
          if (this.page > this.totalPage) return (this.finished = true);
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  computed: {
    ...mapState(['account']),
  },
  watch: {
    account() {
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
.airdrop {
  height: 100%;
  background: url('~@/assets/img/second_bg.png');
  background-size: 100% 100%;
  padding: 20px;
  // min-height: 800px;
  .airdrop_bg {
    width: 100%;
  }
  .contribute {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .contribute_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 46%;
      font-size: 14px;
      background: #252a41;
      border-radius: 6px;
      padding: 10px;
      .contribute_img {
        width: 50px;
        height: 50px;
        margin-top: 10px;
      }
    }
  }
  .receive {
    background: #252a41;
    border-radius: 6px;
    padding: 20px 10px;
    .receive_li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      background: #484d62;
      border-radius: 6px;
      padding: 20px;
      margin-bottom: 20px;
    }
    .receive_button {
      width: 100%;
      height: auto;
      border-radius: 4px;
      border: 0;
      background: linear-gradient(180deg, #1ad9f3 0%, #2a88cc 100%);
      padding: 10px 0;
    }
  }
  .recording {
    background: #252a41;
    border-radius: 6px;
    margin: 20px 0;
    .heading {
      border-bottom: 1px solid #474e5f;
      padding: 20px;
    }
    .current {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 10px;
      span {
        width: 30%;
      }
    }
    .list {
      padding: 10px;
      .li {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        span {
          width: 30%;
        }
      }
    }
    .special {
      text-align: center;
    }
    .particular {
      display: flex;
      justify-content: flex-end;
    }
  }
}


.nothing {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;
      padding-bottom: 10px;
      margin: 60 0 50px;
      .nothing_img {
        width: 200px;
      }
    }
</style>